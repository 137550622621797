


import { Component, Vue, Watch } from "vue-property-decorator";
import User from "@/api/User";

@Component
export default class UserRequired extends Vue {
  private UM = User.um;
  @Watch("UM.loggedIn")
  onUserChanged(newUser: boolean): void {
    if (!newUser) {
      this.$router.push(`/login?from=${this.$route.path}`);
    }
  }

  created(): void {
    if (!this.UM.loggedIn) {
      this.$router.push(`/login?from=${this.$route.path}`);
    }
  }
}
