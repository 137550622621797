














































































import { Vue, Component } from "vue-property-decorator";
import UserRequired from "../components/UserRequired.vue";
import User from "@/api/User";
import { Database } from "sql.js";
import error from "@/api/errors";

Vue.component("UserRequired", UserRequired);

type Pill = {
  id: number;
  name: string;
  amount: string;
  sn: string;
  other: string;
};

type TodayItem = {
  id: number;
  name: string;
  lastTaken: Date | null;
  countToday: number;
  _rowVariant: string | null;
};

type LogItem = {
  rowid: number;
  name: string;
  time: Date;
  count: number;
  id: number;
  _rowVariant: string | null;
};

@Component
export default class DrugLog extends Vue {
  /**
   * Drug Stuff!
   */
  private UM = User.um;
  private pills: Pill[] = [];
  private pillEdit:
    | {
        submitText: "Add";
      }
    | {
        submitText: "Edit";
        currentPill: Pill;
      } = { submitText: "Add" };
  private pillDialogOverlay = false;
  private pillTableOverlay = true;
  private pillsLoading = true;
  private pillFields = [
    { key: "name", sortable: true },
    "amount",
    { key: "sn", label: "Scientific Name" },
    { key: "other", label: "Other Details" },
    { key: "edit", label: "" },
  ];
  private addPill = {
    id: -1,
    name: "",
    amount: "",
    sn: "",
    other: "",
  };
  private db: undefined | Database;
  private showError = error.bind(this, this);

  /**
   * Today Stuff
   */
  private tabTodayActive = false;
  private todayItems: TodayItem[] = [];
  private todayFields = [
    { key: "name", sortable: true, label: "Pill" },
    {
      key: "lastTaken",
      sortable: true,
      label: "Last Taken",
      formatter: (value: Date) => {
        return new Intl.DateTimeFormat(undefined, {
          dateStyle: "medium",
          timeStyle: "medium",
        }).format(value);
      },
    },
    { key: "countToday", sortable: true, label: "# Taken Today" },
    { key: "takeNow", label: "" },
    { key: "history", label: "", class: "d-none d-lg-table-cell" },
  ];

  /**
   * Called when you click the Taken Today Button
   * This function logs a pill to be taken at the current time
   */
  async todayTakeNow(item: TodayItem): Promise<void> {
    if (!this.db) return;
    this.db.run("INSERT INTO takenLog (time, pill, count) values (?, ?, 1);", [
      new Date().valueOf(),
      item.id,
    ]);
    this.save();
    this.updateTodayItems();
    this.updateLogItems();
  }

  /**
   * Called when the history button is pressed on a pill in the today view
   * I want this to open the log screen with only that pill selected in the filter
   */
  async todayHistory(item: TodayItem): Promise<void> {
    this.logFilter = item.name;
    this.tabLogActive = true;
  }

  /**
   * Updates the items listed on the today table
   * This is called every time the list of Pills is updated
   */
  async updateTodayItems(): Promise<void> {
    if (!this.db) return;
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    const result = this.db.exec(
      "SELECT id, name, max(time), " +
        "total(CASE WHEN time > ? THEN count END) " +
        "FROM pills LEFT JOIN takenLog ON pill=id GROUP BY name;",
      [today.valueOf()]
    );
    const tmp = [];
    for (let i = 0; i < result[0].values.length; i++) {
      const row = result[0].values[i];
      tmp.push({
        id: row[0] as number,
        name: row[1] as string,
        lastTaken: row[2] ? new Date(row[2] as number) : null,
        countToday: row[3] as number,
        _rowVariant: (row[3] as number) > 0 ? "primary" : null,
      });
    }
    this.todayItems = tmp;
  }

  // ---------------------------------------
  // Log Stuff
  // ---------------------------------------
  private logPerPage = 10;
  private logCurrentPage = 1;
  private logPageOptions = [10, 25, 50, 100];
  private logEditRowWorking = false;
  private tabLogActive = false;
  private logFilter = "";
  private logItems: LogItem[] = [];
  private logFields = [
    { key: "name", label: "Drug" },
    {
      key: "time",
      label: "Time",
      formatter: (value: Date) => {
        return new Intl.DateTimeFormat("default", {
          dateStyle: "medium",
          timeStyle: "medium",
        }).format(value);
      },
    },
    { key: "count", label: "Count" },
    { key: "edit", label: "" },
    { key: "delete", label: "" },
  ];
  private logEdit = {
    rowid: 0,
    pill: 0,
    date: "",
    time: "",
    count: 0,
    pillOptions: [{ value: 0, text: "" }],
  };

  /**
   * Provides the total number of rows for the pagination element
   */
  get logTotalRows(): number {
    return this.logItems.length;
  }

  /**
   * Updates the log list
   */
  async updateLogItems(): Promise<void> {
    if (!this.db) return;
    const sql =
      "SELECT takenLog.rowid, name, time, count, id FROM takenLog JOIN pills " +
      "ON pill=id;";
    const result = this.db.exec(sql);
    if (result.length !== 0) {
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);
      const tmp = [];
      for (let i = 0; i < result[0].values.length; i++) {
        const row = result[0].values[i];
        tmp.push({
          rowid: row[0] as number,
          name: row[1] as string,
          time: new Date(row[2] as number),
          count: row[3] as number,
          id: row[4] as number,
          _rowVariant: new Date(row[2] as number) > today ? "primary" : null,
        });
      }
      this.logItems = tmp;
    }
  }

  /**
   * Edits a row
   */
  async logEditRow(item: LogItem): Promise<void> {
    if (!this.db) return;
    function pad(num: number) {
      let s = num.toString();
      if (s.length != 2) {
        s = "0" + s;
      }
      return s;
    }
    const time =
      `${pad(item.time.getHours())}:${pad(item.time.getMinutes())}:` +
      `${pad(item.time.getSeconds())}`;
    const date = new Date(
      item.time.valueOf() - item.time.getTimezoneOffset() * 60 * 1000
    )
      .toISOString()
      .slice(0, 10);
    const tmp = [];
    for (let i = 0; i < this.pills.length; i++) {
      tmp.push({ value: this.pills[i].id, text: this.pills[i].name });
    }
    this.logEdit = {
      rowid: item.rowid,
      pill: item.id,
      date: date,
      time: time,
      count: item.count,
      pillOptions: tmp,
    };
    this.$bvModal.show("logEditRow");
  }

  /**
   * Called when the edit button was pressed on the modal
   */
  async logOnEditRow(event: Event): Promise<void> {
    event.preventDefault();
    if (!this.db) return;
    this.logEditRowWorking = true;
    const time = new Date(
      this.logEdit.date + "T" + this.logEdit.time
    ).valueOf();
    this.db.run("UPDATE takenLog SET time=?, pill=?, count=? WHERE rowid=?", [
      time,
      this.logEdit.pill,
      this.logEdit.count,
      this.logEdit.rowid,
    ]);
    this.save();
    this.updateLogItems();
    this.updateTodayItems();
    this.$bvModal.hide("logEditRow");
    this.logEditRowWorking = false;
  }

  /**
   * Deletes the row
   */
  async logDeleteRow(item: LogItem): Promise<void> {
    if (!this.db) return;
    if (
      !(await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to remove taking: ` +
          `${item.count} ${item.name} ` +
          `at ${item.time.toLocaleString()}?`,
        {
          title: "Delete?",
          okTitle: "Yes",
          okVariant: "danger",
          cancelTitle: "No",
          headerBgVariant: "warning",
          bodyBgVariant: "warning",
          footerBgVariant: "warning",
        }
      ))
    )
      return;
    this.db.run("DELETE FROM takenLog WHERE rowid=?", [item.rowid]);
    this.save();
    this.updateLogItems();
    this.updateTodayItems();
  }

  /**
   * Called when the page is loaded
   * This function initializes all the data!
   */
  async created(): Promise<void> {
    if (!this.UM.loggedIn) return;
    await this.UM.user.databaseManager.openDatabase("DrugLog");
    const db = this.UM.user.databaseManager.database;
    if (!db) return;
    this.db = db;
    await db.run(
      "PRAGMA FOREIGN_KEYS=ON;" +
        "CREATE TABLE IF NOT EXISTS pills (id INTEGER PRIMARY KEY, " +
        "name TEXT NOT NULL, enabled INT DEFAULT 1, amount INT, sn TEXT, " +
        "other TEXT);" +
        "CREATE TABLE IF NOT EXISTS takenLog (time INT NOT NULL, " +
        "pill INT REFERENCES pills(id), count INT);" +
        "CREATE TABLE IF NOT EXISTS supplyLog (time INT NOT NULL, " +
        "pillID INT REFERENCES pills(rowid), count INT);"
    );
    // Check for pills
    this.reloadPills();
  }

  /**
   * Saves the whole database on the server
   */
  async save(): Promise<void> {
    if (!this.UM.loggedIn) return;
    await this.UM.user.databaseManager.saveDatabase();
  }

  /**
   * Called when you hit edit next to any pill,
   * This function sets the stage and opens up the pill edit dialog
   */
  private startPillEdit(item: Pill): void {
    // figure out a type
    this.pillEdit = { submitText: "Edit", currentPill: item };
    this.pillEditReset();
    this.$bvModal.show("add-pill");
  }

  /**
   * Sets all the fields in the Pill add/edit dialog to the default values
   */
  private pillEditReset(): void {
    if (this.pillEdit.submitText == "Edit") {
      this.addPill = { ...this.pillEdit.currentPill };
    } else {
      this.addPill = {
        id: -1,
        name: "",
        amount: "",
        sn: "",
        other: "",
      };
    }
  }

  /**
   * Called when the delete pill button is pressed!
   * This will ask for confirmation and then if confirmed,
   * It will delete the pill being edited.
   */
  private async pillDelete(): Promise<void> {
    this.pillDialogOverlay = true;
    if (this.pillEdit.submitText === "Add" || !this.db) return;
    const result = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to delete the pill ${this.pillEdit.currentPill.name}?`,
      {
        okVariant: "danger",
        bodyBgVariant: "warning",
        footerBgVariant: "warning",
      }
    );
    if (result) {
      // check to see if the pill is in use
      const count = this.db.exec(
        "SELECT count(pill) FROM takenLog WHERE pill=?",
        [this.pillEdit.currentPill.id]
      )[0].values[0];
      if (count) {
        // Hide the pill because it is in use
        this.db.run("UPDATE pills SET enabled=0 WHERE id=?", [
          this.pillEdit.currentPill.id,
        ]);
      } else {
        // actually delete the pill
        this.db.run("DELETE FROM pills WHERE id = ?", [
          this.pillEdit.currentPill.id,
        ]);
      }
      await this.save();
      this.reloadPills();
      const id = this.pills.indexOf(this.pillEdit.currentPill);
      this.pills.splice(id, 1);
      if (this.pills.length === 0) this.pillTableOverlay = true;
      this.$bvModal.hide("add-pill");
    }
    this.pillDialogOverlay = false;
  }

  /**
   * Called to load all the current pill data
   */
  async reloadPills(): Promise<void> {
    if (!this.db) return;
    const result = this.db.exec(
      "SELECT id, name, amount, sn, other FROM pills WHERE enabled=1;"
    );
    if (result.length == 0) {
      // show the overllay if there are no pills
      this.pillTableOverlay = true;
      this.pills = [];
    } else {
      const tmp = [];
      for (let i = 0; i < result[0].values.length; i++) {
        const value = result[0].values[i];
        tmp.push({
          id: value[0] as number,
          name: value[1] as string,
          amount: value[2] as string,
          sn: value[3] as string,
          other: value[4] as string,
        });
      }
      this.pills = tmp;
      // Hide the overlay if there is data
      this.pillTableOverlay = false;
      this.updateTodayItems();
      this.updateLogItems();
    }
    // Pills are now loaded, lets make sure that says so
    if (this.pillsLoading) {
      this.pillsLoading = false;
      this.tabTodayActive = true;
    }
  }

  /**
   * Called when you hit the 'submit' button on the Pill Add/Edit dialog
   */
  async onAddPill(evt: Event): Promise<void> {
    evt.preventDefault();
    this.pillDialogOverlay = true;
    const result = await (async () => {
      if (!this.db) {
        return;
      }
      switch (this.pillEdit.submitText) {
        case "Add": // Handle adding the new pill
          if (this.pills.find((e) => e.name === this.addPill.name)) {
            this.showError("Pillname already in use!");
            return;
          }
          await this.db.run(
            "INSERT INTO pills (name, amount, sn, other) VALUES (?, ?, ?, ?);",
            [
              this.addPill.name,
              this.addPill.amount,
              this.addPill.sn,
              this.addPill.other,
            ]
          );
          this.save();
          this.reloadPills();
          this.$bvModal.hide("add-pill");
          break;
        case "Edit": // Handle changing the pill data
          if (
            this.addPill.name !== this.pillEdit.currentPill.name &&
            this.pills.find((e) => e.name === this.addPill.name)
          ) {
            this.showError("Pillname already in use!");
            return;
          }
          await this.db.run(
            "UPDATE pills SET name = ?, amount = ?, sn = ?, other = ? WHERE rowid = ?",
            [
              this.addPill.name,
              this.addPill.amount,
              this.addPill.sn,
              this.addPill.other,
              this.addPill.id,
            ]
          );
          this.save();
          this.pillEdit = { submitText: "Add" };
          this.reloadPills();
          this.$bvModal.hide("add-pill");
          break;
      }
    })();
    this.pillDialogOverlay = false;
    return result;
  }
}
